import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import styled from '@emotion/styled';

import App from '../components/layout/App';
import Hero from '../components/base/Hero';
import Wrapper from '../components/base/Wrapper';
import ProjectCard from '../components/base/ProjectCard';
import { color, spacing, mqUp } from '../constants/styles';
import referencesProjects from '../images/illustrations/side-projects.svg';

const Container = styled.div`
  padding: 0 0 ${spacing.XXXXL} 0;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: ${spacing.L};
  ${mqUp('tablet')} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${mqUp('desktop')} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const SideProjectsPage = props => {
  const DATA = props.data.projects.data;
  return (
    <App
      title={DATA.seo_title}
      description={DATA.seo_description}
      image={DATA.seo_image.url}
    >
      <Hero
        titleLight={DATA.title_light}
        titleBold={DATA.title_bold}
        gradient={{start: '#F54E81', end: '#F5CA85'}}
        illustration={referencesProjects}
      />
      <Container>
        <Wrapper
          css={css`
            margin-bottom: ${spacing.XXL};
          `}
        >
          <Grid>
            {DATA.card.map(item => (
              <ProjectCard
                key={item.title}
                title={item.title}
                thumb={item.thumb}
                content={item.content.html}
                tags={item.tags.html}
              />
            ))}
          </Grid>
        </Wrapper>
      </Container>
    </App>
  );
};

export default SideProjectsPage;

export const sideProjectsQuery = graphql`
  query SideProjectsQuery {
    projects: prismicSideProjects {
      data {
        seo_title
        seo_description
        seo_image {
          url
        }
        title_light
        title_bold
        card {
          title
          content {
            html
          }
          tags {
            html
          }
          thumb {
            url
          }
        }
      }
    }
  }
`;
